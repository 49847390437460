import cont_match_reg from "@/utils/editor.js";
import { getItemAuditReason } from "@api/index";
import { mdToast } from "../../utils/tools";
export default {
  async mounted() {
    const query = this.$route.query;
    if (query.type && query.id) {
      const {
        status,
        data,
        message
      } = await getItemAuditReason({
        type: query.type,
        id: query.id
      });
      if (status == 0) {
        this.$refs.editorBox.appendChild(cont_match_reg(`<p>${data.content}</p>`));
      } else {
        mdToast(message);
      }
    }
  }
};